<template>
  <v-container>
    <v-row>
      <v-col
        xl="9"
        md="9"
        sm="12"
        cols="12"
      >
        <div class="headline">
          <v-img
            v-if="$store.state.company === 'Systechgroup'"
            style="max-width: 220px;"
            src="../assets/systech.png"
          />
          <img
            v-else
            style="max-width: 220px; margin-top: 5px; max-height: 60px; margin-left: 20px;"
            :src="fileURL + $store.state.userDetails.companyLogo"
          >
        </div>
      </v-col>

      <v-col
        xl="3"
        md="3"
        sm="12"
        cols="12"
      >
        <v-text-field
          v-model="search"
          label="Hledat"
          solo
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-for="machine in lodash.orderBy(customerMachines, ['id'], ['desc'])"
        :key="machine.id"
        xl="6"
        lg="12"
        md="12"
        sm="12"
        cols="12"
      >
        <v-card
          style="position: relative;"
          class="portal-card pl-5 pr-5 pb-5"
          elevation="3"
        >
          <v-card-title>
            <span
              :style="machine.cyclesTillNextMaintenance > 0 && machine.daysTillNextMaintenance > 0 ? 'color: green;' : 'color: red;'"
            >{{
              machine.machineType }} - {{ machine.machineModel }}</span>
            <v-icon
              v-if="machine.cyclesTillNextMaintenance > 0 && machine.daysTillNextMaintenance > 0"
              class="ml-3"
              color="green"
            >
              mdi-check-circle
            </v-icon>
            <v-icon
              v-else
              class="ml-3"
              color="red"
            >
              mdi-alert
            </v-icon>
          </v-card-title>
          <v-card-text>
            <v-row>
              <!-- <v-col
                xl="2"
                sm="3"
                cols="12"
              >
                <img
                  class="center-on-600"
                  style="max-width: 170px; max-height: 125px;"
                  :src="fileURL + machine.machineImage"
                >
              </v-col> -->
              <v-col
                sm="6"
                cols="12"
                class="text--dark-gray"
              >
                <div style="display: inline-block;">
                  <img
                    class="center-on-600"
                    style="max-width: 100px; max-height: 125px;"
                    :src="fileURL + machine.machineImage"
                  >
                </div>
                <div
                  style="display: inline-block;"
                  class="info-block"
                >
                  <div v-if="$store.state.role === '100100'">
                    <span class="font-weight-bold">Zákazník: <span class="text--orange">{{ machine.customer }}</span></span>
                    <div><span class="font-weight-medium">Servisni smlouva: </span> <span :class="machine.serviceContract ? 'text--green' : 'text--red'">{{ machine.serviceContract || 'N/A' }}</span></div>
                  </div>
                  <div><span class="font-weight-medium">Status stroje:</span> {{ machine.machineStatus }}</div>
                  <div v-if="machine.serviceEveryXCycles && machine.serviceEveryXDays">
                    <span class="font-weight-medium">Příští údržba:</span> <span
                      :class="machine.cyclesTillNextMaintenance <= 0 ? 'text--red font-weight-bold' : ''"
                    >{{
                      machine.cyclesTillNextMaintenance || 'N/A' }} cyklu</span> /<span
                      :class="machine.daysTillNextMaintenance <= 0 ? 'text--red font-weight-bold' : ''"
                    >{{
                      machine.daysTillNextMaintenance }} dnu <span
                      v-if="machine.daysTillNextMaintenance <= 0"
                      color="red"
                    >!</span></span>
                  </div>
                  <div v-else>
                    <span class="font-weight-medium">Příští údržba:</span> <span class="text--orange">Chybi udaje</span>
                  </div>
                  <div><span class="font-weight-medium">Sériové číslo:</span> {{ machine.machineSerialNumber }}</div>
                  <div>
                    <span class="font-weight-medium">Počet cyklů:</span> {{ machine.machineCycles || 'Neuvedeno' }}
                  </div>

                  <div v-if="$store.state.role === '100100'">
                    <span class="font-weight-medium">Zodpovědná osoba:</span> {{ machine.responsiblePerson ?
                      machine.responsiblePerson : 'N/A' }}
                  </div>
                  <div v-if="$store.state.role === '100100'">
                    <span class="font-weight-medium">Telefon:</span>
                    <a
                      v-if="findCustomerDetails(machine.userId).userPhoneNumber"
                      :style="findCustomerDetails(machine.userId).userPhoneNumber ? 'text-decoration: underline; color: #ff5722;' : 'color: red; text-decoration: none;'"
                      :href="`tel:${findCustomerDetails(machine.userId).userPhoneNumber}`"
                    >
                      {{ findCustomerDetails(machine.userId).userPhoneNumber }}
                    </a>
                    <span
                      v-else
                      style="color: red;"
                    > Telefon neuveden </span>
                  </div>
                </div>
              </v-col>
              <v-col>
                <div class="controls-status">
                  <img
                    v-if="$store.state.role === '100100'"
                    class="customer-logo"
                    :src="fileURL + findCustomerDetails(machine.userId).companyLogo"
                  >
                  <div
                    class="button"
                    style="position: absolute; bottom: 20px; right: 20px;"
                  >
                    <v-btn
                      :loading="loading"
                      :disabled="loading"
                      color="tertiary"
                      dark
                      @click="machineDialogData = machine; machineDialog = !machineDialog"
                    >
                      Více
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!customerMachines.length">
      <v-col style="text-align:center">
        <div>
          <v-icon
            color="tertiary"
            style="font-size: 45px;"
          >
            mdi-database-remove-outline
          </v-icon>
        </div>
        <div class="mt-5">
          Nebyly nalezeny žádné stoje
        </div>
      </v-col>
    </v-row>
    <machine-dialog
      :machine-dialog="machineDialog"
      :machine-dialog-data="machineDialogData"
      @close="machineDialog = false; machineDialogData = null"
      @updateAll="getCustomerMachines()"
    />
  </v-container>
</template>

<script>
import machineDialog from '../components/MachineDialog.vue'

import _ from 'lodash'

export default {
  name: 'Portal',
  components: {
    machineDialog
  },
  data: () => ({
    lodash: _,
    test: '',
    loading: false,
    machines: [],
    selectedIndex: null,
    machineDialog: false,
    machineDialogData: null,
    fileURL: process.env.VUE_APP_API_FILES,
    search: ''
  }),
  computed: {
    customerMachines () {
      const items = this.$store.state.customerMachines
      return items.filter(item => {
        return (item.customer ? item.customer.toLowerCase() : '').toLowerCase().includes(this.search.toLowerCase()) ||
          item.machineSerialNumber.toLowerCase().includes(this.search.toLowerCase()) ||
          item.machineModel.toLowerCase().includes(this.search.toLowerCase()) ||
          item.machineType.toLowerCase().includes(this.search.toLowerCase()) ||
          (item.responsiblePerson ? item.responsiblePerson.toLowerCase() : '').includes(this.search.toLowerCase())
      })
    },
    allCustomers () {
      return this.$store.state.allPortalUsers
    },
    testing () {
      return this.test.toLowerCase()
    }

  },
  mounted () {
    this.getCustomerMachines()
  },
  methods: {
    getCustomerMachines () {
      if (this.$store.state.role === '100100') {
        this.$store.dispatch('getAllCustomers').then(() => {
          this.$store.dispatch('adminGetCustomerMachines')
        })
      } else {
        this.$store.dispatch('getCustomerMachines')
      }
    },
    findCustomerDetails (id) {
      return this.allCustomers.filter(item => item.id === id)[0]
    }
  }
}
</script>

<style scoped>
.portal-card {
  margin: 0px;
  min-height: 300px;
}

.controls-status {
  text-align: right;
}

.button {
  margin-top: 30px;
  position: relative;
  bottom: 0px;
}

.info-block {
  max-width: 70%;
  margin-left: 20px;
}

.transition {
  transition: height 1s ease-in-out;
}

.customer-logo {
  max-width: 180px;
  max-height: 100px;
  margin-top: -50px;
}

@media only screen and (max-width: 599px) {
  .center-on-600 {
    margin: 0 auto;
  }

  .customer-logo {
    position: absolute;
    top: 50px;
    right: 10px;
    margin-top: 0px;
  }

  .info-block {
    margin-left: 0;
  }

  .status {
    display: inline-block;
    width: 10%;
  }

  .button {
    display: inline-block;
    margin-top: 0;
    width: 90%;
    bottom: 0px;
  }

}

@media only screen and (max-width: 1400px) {
  .customer-logo {
    max-width: 100px;
    margin-bottom: 10px;
    margin-top: 0px;
  }
}
</style>
