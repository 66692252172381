<template>
  <div class="text-center">
    <v-dialog
      :value="machineDialog"
      width="900"
      @click:outside="closeDialog"
    >
      <v-card>
        <v-card-title class="text-h5 secondary-background white--text">
          <div>{{ machineType }} - {{ machineModel }}</div>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col
              sm="3"
              cols="12"
              class="pa-0 ma-0 mt-3"
            >
              <v-list class="hidden-xs-only pr-3">
                <v-list-item
                  style=" border-radius: 5px;"
                  :class="selectedView === 1 ? 'active-button white--text' : ''"
                  @click="selectedView = 1; consoled()"
                >
                  <v-icon
                    class="mr-3"
                    :class="selectedView === 1 ? 'white--text' : ''"
                  >
                    mdi-information-outline
                  </v-icon>
                  Informace
                </v-list-item>
                <v-list-item
                  color="secondary"
                  style=" border-radius: 5px;"
                  :class="selectedView === 2 ? 'active-button white--text' : ''"
                  @click="selectedView = 2"
                >
                  <v-icon
                    class="mr-3"
                    :class="selectedView === 2 ? 'white--text' : ''"
                  >
                    mdi-clipboard-list-outline
                  </v-icon>
                  Záznamy
                </v-list-item>
              </v-list>
              <div class="hidden-sm-and-up mt-2">
                <v-btn
                  text
                  dense
                  :class="selectedView === 1 ? 'active-button white--text' : ''"
                  @click="selectedView = 1"
                >
                  <v-icon
                    class="mr-1"
                    :class="selectedView === 1 ? 'white--text' : ''"
                  >
                    mdi-information-outline
                  </v-icon>
                  Informace
                </v-btn>
                <v-btn
                  text
                  dense
                  :class="selectedView === 2 ? 'active-button white--text' : ''"
                  @click="selectedView = 2"
                >
                  <v-icon
                    class="mr-1"
                    :class="selectedView === 2 ? 'white--text' : ''"
                  >
                    mdi-clipboard-list-outline
                  </v-icon>
                  Zaznamy
                </v-btn>
              </div>
            </v-col>
            <v-divider vertical />
            <v-col>
              <v-expand-transition mode="out-in">
                <div
                  v-if="selectedView === 1"
                  key="1"
                  class="text--dark-gray mt-5"
                >
                  <v-row class="mt-3 ml-3 mb-3">
                    <div>
                      <v-btn
                        v-if="$store.state.role === '100100'"
                        color="tertiary"
                        dark
                        :loading="loading"
                        :disabled="loading"
                        @click="selectedView = 3"
                      >
                        Vlozeni Servisu
                      </v-btn>
                      <v-btn
                        :loading="loading"
                        :disabled="loading"
                        class="ml-2"
                        color="tertiary"
                        dark
                        @click="selectedView = 4"
                      >
                        Vlozeni cyklu
                      </v-btn>
                    </div>
                  </v-row>
                  <v-row>
                    <v-col
                      class="hide-on-mobile"
                      cols="3"
                    >
                      <v-img
                        class="ml-2"
                        :src="fileURL + machineImage"
                        style="max-width: 150px"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      class="ml-1"
                    >
                      <div>
                        <span class="font-weight-medium">Status stroje:</span>
                        {{ machineStatus }}
                      </div>
                      <div>
                        <span class="font-weight-medium">Sériové číslo:</span>
                        {{ machineSerialNumber }}
                      </div>
                      <div>
                        <span class="font-weight-medium">Příští údržba: </span>
                        <span class="tertiary--text">{{ nextMaintenace }}</span>
                      </div>
                      <div>
                        <span class="font-weight-medium">Počet cyklů:</span>
                        {{ machineCycles }}
                      </div>
                      <div>
                        <span class="font-weight-medium">Cislo objednávky:</span>
                        {{ systechOrderNumber }}
                      </div>
                      <div>
                        <span class="font-weight-medium">Rok výroby:</span>
                        {{ dayjs(dateOfManufacture).format('DD MMM YYYY') }}
                      </div>
                      <div>
                        <span class="font-weight-medium">Datum instalace:</span>
                        {{ dayjs(installationDate).format('DD MMM YYYY') }}
                      </div>
                      <div>
                        <span class="font-weight-medium">Adresa: </span>
                        <span class="tertiary--text text-decoration-underline">{{ machineAddress.street }}, {{ machineAddress.city }}, {{ machineAddress.postCode }}</span>
                      </div>
                      <div>
                        <span class="font-weight-medium">Zákazník: </span>
                        <span class="tertiary--text">{{ customer }}</span>
                      </div>
                      <div>
                        <span
                          class="font-weight-medium"
                        >Zodpovědná osoba:</span>
                        {{ responsiblePerson }}
                      </div>
                    </v-col>
                  </v-row>
                  <!-- <div
                    class="headline mt-5"
                  >
                    Poznamky
                  </div> -->
                  <v-row
                    class="ml-2 mt-3"
                    style="margin-bottom: 0px;"
                  >
                    <v-textarea
                      v-model="machineNotes"
                      color="tertiary"
                      :disabled="loading"
                      outlined
                      no-resize
                      height="80px"
                      label="Poznámky"
                      value="Zde je prostor pro doplnění, případné poznámky."
                    />
                  </v-row>
                  <div style="text-align: right;">
                    <v-icon
                      v-if="saved"
                      color="green"
                      class="mr-3"
                    >
                      mdi-check
                    </v-icon>
                    <v-btn
                      color="tertiary"
                      dark
                      @click="saveNotesForCustomerMachine"
                    >
                      Ulozit poznamky
                    </v-btn>
                  </div>
                  <div
                    class="headline mt-5"
                  >
                    Soubory
                  </div>
                  <v-row
                    class="ml-2 mt-1"
                    style="margin-bottom: 0px;"
                  >
                    <v-col cols="12">
                      <div style="position: relative">
                        <v-file-input
                          v-model="newFiles"
                          color="tertiary"
                          class="custom-label-color"
                          label="Nové soubory"
                          multiple
                          outlined
                          :disabled="uploading"
                          dense
                        />
                        <v-btn
                          v-if="newFiles !== null"
                          color="tertiary"
                          dark
                          style="float: right;"
                          @click="addFileToMachine"
                        >
                          Přidat soubor
                        </v-btn>
                        <v-progress-circular
                          v-if="uploading"
                          style="margin: 0 auto; position: absolute; top: 4px;; left: 50%;"
                          color="tertiary"
                          indeterminate
                        />
                      </div>
                      <v-row class="mb-1">
                        <div
                          v-for="file in files"
                          :key="file"
                          style="cursor: pointer; border: 1px solid lightgray; border-radius: 5px; width: 140px; text-align: center; position: relative; margin: 5px; padding: 5px;"
                          class="hoover"
                          @click="openLink(file)"
                        >
                          <v-icon
                            :color="mapFileExtension(file).color"
                            style="font-size: 85px;"
                          >
                            {{ mapFileExtension(file).icon }}
                          </v-icon>
                          <div style="text-decoration: underline; font-size: 12px; text-align: center;">
                            {{ mapFileName(file) }}
                          </div>
                          <v-icon
                            color="red"
                            small
                            style="position: absolute; top: 0px; right: 0px;"
                            @click="$event.stopPropagation(); deleteFile(file);"
                          >
                            mdi-close
                          </v-icon>
                        </div>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>

                <div
                  v-else-if="selectedView === 2"
                  key="2"
                >
                  <div>
                    <div class="headline mt-5 ml-3">
                      Záznam Servisu
                    </div>
                    <div>
                      <v-data-table
                        :footer-props="footerProps"
                        :headers="headersService"
                        :items="machineServiceRecords"
                        :items-per-page="4"
                        class="elevation-0 ml-3"
                        dense
                      >
                        <template v-slot:item.serviceDate="{ item }">
                          {{ dayjs(item.serviceDate).format('YY-MM-DD HH:mm') }}
                        </template>
                        <template v-slot:top>
                          <v-dialog
                            v-model="dialogDelete"
                            max-width="300px"
                          >
                            <v-card>
                              <v-card-title>
                                Deleting service record
                              </v-card-title>
                              <v-card-text>
                                <div v-if="!loading">
                                  Opravdu si přejete odstranit záznam servisu?
                                </div>
                                <div v-else-if="loading">
                                  Načítání...
                                </div>
                              </v-card-text>
                              <v-card-actions>
                                <v-spacer />
                                <v-btn

                                  :disabled="loading"
                                  text
                                  @click="dialogDelete = false"
                                >
                                  Zrušit
                                </v-btn>
                                <v-btn

                                  :disabled="loading"
                                  color="tertiary"
                                  text
                                  @click="deleteService()"
                                >
                                  Odstranit
                                </v-btn>
                                <v-spacer />
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </template>
                        <template v-slot:item.serviceEdit="{ item }">
                          <v-icon

                            :disabled="loading"

                            color="primary"
                            class="mr-2"
                            dark
                            @click="editService(item)"
                          >
                            mdi-magnify
                          </v-icon>
                          <v-icon
                            color="red"

                            @click="deleteServiceVerify(item.id)"
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                      </v-data-table>
                    </div>

                    <div class="headline ml-3 mt-3">
                      Záznam Cyklu
                    </div>
                    <div>
                      <v-data-table
                        :footer-props="footerProps"
                        :headers="headers"
                        :items="machineCycleRecords"
                        :items-per-page="4"
                        class="elevation-0 ml-3"
                        dense
                        :sort-by="['id']"
                        :sort-desc="[true]"
                      >
                        <template v-slot:item.createdAt="{ item }">
                          {{ dayjs(item.createdAt).format('YY-MM-DD HH:mm') }}
                        </template>
                        <template v-slot:top>
                          <v-dialog
                            v-model="dialogDeleteCycle"
                            max-width="300px"
                          >
                            <v-card>
                              <v-card-title>
                                Deleting cycle record
                              </v-card-title>
                              <v-card-text>
                                <div v-if="!loading">
                                  Opravdu si přejete odstranit záznam cyklu?
                                </div>
                                <div v-else-if="loading">
                                  Načítání...
                                </div>
                              </v-card-text>
                              <v-card-actions>
                                <v-spacer />
                                <v-btn

                                  :disabled="loading"
                                  text
                                  @click="dialogDeleteCycle = false"
                                >
                                  Zrušit
                                </v-btn>
                                <v-btn

                                  :disabled="loading"
                                  color="tertiary"
                                  text
                                  @click="deleteCycle()"
                                >
                                  Odstranit
                                </v-btn>
                                <v-spacer />
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </template>
                        <template v-slot:item.cycleEdit="{ item }">
                          <v-icon
                            color="red"

                            @click="deleteCycleVerify(item.id)"
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                      </v-data-table>
                    </div>
                  </div>
                </div>
                <div v-else-if="selectedView === 3">
                  <div class="headline mt-3">
                    Vložení servisu
                  </div>
                  <div class="checkbox-service">
                    <v-checkbox
                      class="ml-3"
                      label="Neočekávaný servis"
                      color="tertiary"
                      :disabled="checkTwo || checkThree"
                      @click="checkOne = !checkOne"
                    />
                    <v-checkbox
                      color="tertiary"
                      class="ml-3"
                      label="Naplánovaná údržba"
                      :disabled="checkOne || checkThree"
                      @click="checkTwo = !checkTwo"
                    />
                    <v-checkbox
                      class="ml-3"
                      color="tertiary"
                      label="Servis softwaru"
                      :disabled="checkTwo || checkOne"
                      @click="checkThree = !checkThree"
                    />
                  </div>
                  <v-text-field
                    v-model="pickedDate"
                    outlined
                    dense
                    prepend-icon="mdi-flag"
                    label="Datum"
                    @click="openPicker()"
                  />
                  <v-date-picker
                    v-if="picker"
                    v-model="pickedDate"
                    locale="sk-SK"
                    elevation="2"

                    header-color="#3d5a81"
                    color="tertiary"

                    class="position-absolute"
                    @input="picker=false"
                  />
                  <v-text-field
                    v-model="numberOfCycles"
                    label="Počet cyklů"
                    outlined
                    dense
                    prepend-icon="mdi-sync"
                  />

                  <!-- <div class="file-upload">
                    <div>
                      <div class="file-upload-inner">
                        <v-icon
                          color="tertiary"
                          x-large
                        >
                          mdi-cloud-upload-outline
                        </v-icon>
                      </div>
                      <div>Zde přetáhněte soubory</div>
                    </div>
                  </div> -->

                  <v-file-input
                    v-model="serviceFiles"
                    multiple
                    :disabled="loading"
                    color="tertiary"
                    label="Soubory"
                    outlined
                    dense
                  />
                  <v-textarea
                    v-model="notes"
                    color="tertiary"
                    :disabled="loading"
                    outlined
                    no-resize
                    height="90px"
                    label="Poznámky"
                  />
                  <v-btn
                    style="float: right;"
                    color="tertiary"
                    dark
                    @click="createServiceRecords"
                  >
                    Přidat
                  </v-btn>
                </div>
                <div v-else-if="selectedView === 4">
                  <div class="headline mt-3">
                    Přidat počet cyklů
                  </div>
                  <div class="mt-3">
                    <v-text-field
                      v-model="cycleEntry"
                      outlined
                      dense
                      type="number"
                      min="0"
                      label="Počet cyklů"
                    />
                  </div>
                  <div>
                    <v-btn
                      :loading="loading"
                      :disabled="loading"
                      color="tertiary"
                      dark
                      style="float: right"
                      @click="setCycleRecord"
                    >
                      Přidat
                    </v-btn>
                  </div>
                </div>
                <div v-else-if="selectedView === 5">
                  <div class="headline mt-3 black--text">
                    {{ serviceEditView.typeOfService }}
                  </div>

                  <v-text-field
                    v-model="serviceDate"
                    class="mt-2"
                    outlined
                    dense
                    prepend-icon="mdi-flag"
                    label="Datum"
                  />

                  <v-text-field
                    v-model="serviceEditView.cycleCount"
                    :editable="false"
                    label="Počet cyklů"
                    outlined
                    dense
                    prepend-icon="mdi-sync"
                  />

                  <v-textarea
                    v-model="serviceEditView.serviceNotes"
                    color="tertiary"
                    :disabled="loading"
                    outlined
                    no-resize
                    height="90px"
                    label="Poznámky"
                  />
                  <!-- <v-file-input
                    v-model="serviceEditView.serviceFiles"
                    multiple
                    :disabled="loading"
                    color="tertiary"
                    label="Soubory"
                    outlined
                    dense
                  /> -->
                  <div
                    v-for="file in serviceEditView.serviceFiles"
                    :key="file.id"
                    style="cursor: pointer; border: 1px solid lightgray; border-radius: 5px; width: 140px; text-align: center; position: relative; margin: 5px; padding: 5px;"
                    class="hoover"
                    @click="openLink(file)"
                  >
                    <v-icon
                      :color="mapFileExtension(file).color"
                      style="font-size: 85px;"
                    >
                      {{ mapFileExtension(file).icon }}
                    </v-icon>
                    <div style="text-decoration: underline; font-size: 12px; text-align: center;">
                      {{ mapFileName(file) }}
                    </div>
                  </div>
                </div>
              </v-expand-transition>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            :loading="loading"
            :disabled="loading"
            color="primary"
            text
            @click="closeDialog"
          >
            Zavřít
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: {
    machineDialog: Boolean,
    machineDialogData: { type: Object, default: null }
  },
  data () {
    return {
      selectedView: 1,
      loading: false,
      cycleEntry: 0,
      picker: false,
      checkOne: false,
      checkTwo: false,
      checkThree: false,
      serviceEditView: null,
      pickedDate: dayjs().format('YYYY-MM-DD'),
      dayjs,
      headers: [
        { text: '#', value: 'id' },
        { text: 'Datum', value: 'createdAt' },
        { text: 'Počet cyklů', value: 'cycleEntry' },
        { text: 'Zadal', value: 'enteredBy' },
        { text: 'Detail', value: 'cycleEdit', sortable: false, align: 'end' }
      ],
      headersService: [
        { text: '#', value: 'id' },
        { text: 'Datum', value: 'serviceDate' },
        { text: 'Počet cyklů', value: 'cycleCount' },
        { text: 'Zadal', value: 'enteredBy' },
        { text: 'Detail', value: 'serviceEdit', sortable: false, align: 'end' }
      ],
      machineServiceRecords: [],
      machineCycleRecords: [],
      footerProps: {
        'disable-items-per-page': true
      },
      numberOfCycles: 0,
      versionOfCopilot: '',
      notes: '',
      serviceFiles: null,
      machineId: 0,
      machineType: '',
      machineModel: '',
      machineImage: '',
      serviceDate: dayjs().format('YYYY-MM-DD'),
      machineStatus: '',
      machineSerialNumber: '',
      machineCycles: '',
      systechOrderNumber: '',
      dateOfManufacture: '',
      machineAddress: '',
      machineNotes: '',
      customer: '',
      responsiblePerson: '',
      nextMaintenace: '',
      installationDate: '',
      dialogDelete: false,
      saved: false,
      serviceId: null,
      newFiles: null,
      uploading: false,
      files: null,
      dialogDeleteCycle: false,
      fileURL: process.env.VUE_APP_API_FILES
    }
  },
  watch: {
    machineDialog: function (val) {
      if (val) {
        // console.log(this.machineDialogData)
        this.machineId = this.machineDialogData.id
        this.machineType = this.machineDialogData.machineType
        this.machineModel = this.machineDialogData.machineModel
        this.machineImage = this.machineDialogData.machineImage
        this.machineNotes = this.machineDialogData.notes
        this.machineSerialNumber = this.machineDialogData.machineSerialNumber
        this.machineCycles = this.machineDialogData.machineCycles
        this.systechOrderNumber = this.machineDialogData.systechOrderNumber
        this.dateOfManufacture = this.machineDialogData.dateOfManufacture
        this.installationDate = this.machineDialogData.installationDate
        this.machineAddress = this.machineDialogData.machineAddress
        this.customer = this.machineDialogData.customer
        this.responsiblePerson = this.machineDialogData.responsiblePerson
        this.machineImage = this.machineDialogData.machineImage
        this.nextMaintenace = `${dayjs(this.machineDialogData.installationDate).add(365, 'days').format('DD MMM YYYY')}`
        this.files = this.machineDialogData.files
        this.pullMachineData()
        this.getCycleRecords()
        this.getServiceRecords()
      }
    },
    datePicked: function (value) {
      // console.log(value)
    }
  },
  mounted () {},
  methods: {
    closeDialog () {
      this.$emit('close')
      this.selectedView = 1
      this.newFiles = null
    },
    consoled () {
      // console.log(this.machineDialogData.id)
    },
    pullMachineData () {
      // console.log(
      //   'call store for data from API for machine ' + this.machineDialogData.id
      // )
    },
    openPicker () {
      this.picker = !this.picker
    },
    getCycleRecords () {
      this.$store.dispatch('getCycleRecords', this.machineDialogData.id)
        .then(resp => {
          this.machineCycleRecords = resp.data
        })
        .catch(err => {
          this.errorMessage = err.response.data
        })
    },
    getServiceRecords () {
      this.$store.dispatch('getServiceRecords', this.machineDialogData.id)
        .then(resp => {
          this.machineServiceRecords = resp.data
        })
        .catch(err => {
          this.errorMessage = err.response.data
        })
    },
    addFileToMachine () {
      this.uploading = true
      const fileData = new FormData()
      for (const file of this.newFiles) {
        fileData.append('files', file)
      }
      this.$store.dispatch('uploadFiles', fileData)
        .then(resp => {
          // if file uploaded successfully you will receive array of links then needs to be passed to createNewCustomer
          // console.log(resp.data)
          // this.createNewCustomer(newUser, resp.data)
          // console.log(resp.data)
          resp.data.forEach(item => {
            this.files.push(item)
          })
          this.newFiles = null
          this.uploading = false
          this.updateMachine()
        })
        .catch(err => {
          this.errorMessage = err.response.data
          this.uploading = false
          this.loading = false
          this.files = null
          this.newFiles = null
        })
    },
    updateMachine () {
      this.$store.dispatch('updateFilesForCustomerMachine', { machineId: this.machineId, files: this.files })
    },
    saveNotesForCustomerMachine () {
      this.saved = true
      setTimeout(() => {
        this.saved = false
      }, 2000)
      this.$store.dispatch('updateNotesForCustomerMachine', { machineId: this.machineId, notes: this.machineNotes })
    },
    createServiceRecords () {
      this.loading = true
      let typeOfService = ''
      let idOfService = 0
      if (this.checkOne) {
        typeOfService = 'Neočekávaný servis'
        idOfService = 1
      } else if (this.checkTwo) {
        typeOfService = 'Naplánovaná údržba'
        idOfService = 2
      } else if (this.checkThree) {
        typeOfService = 'Servis softwaru'
        idOfService = 3
      }
      const serviceRecord = {
        serviceDate: this.pickedDate, cycleCount: this.numberOfCycles, enteredBy: this.$store.state.userName, typeOfService, serviceNotes: this.notes, serviceFiles: [], copilotVersion: this.versionOfCopilot, idOfService
      }
      if (this.serviceFiles) {
        const fileData = new FormData()
        for (const file of this.serviceFiles) {
          fileData.append('files', file)
        }
        this.$store.dispatch('uploadFiles', fileData)
          .then(resp => {
            resp.data.forEach(item => {
              serviceRecord.serviceFiles.push(item)
              this.postServiceRecords(serviceRecord)
            })

            this.serviceFiles = null
          })
          .catch(err => {
            this.errorMessage = err.response.data

            this.loading = false
            this.serviceFiles = null
          })
      } else {
        this.postServiceRecords(serviceRecord)
      }
    },
    postServiceRecords (serviceRecord) {
      this.$store.dispatch('postServiceRecords', { serviceRecord, machineId: this.machineDialogData.id })
        .then(resp => {
          this.getServiceRecords()
          this.checkOne = false
          this.checkTwo = false
          this.checkThree = false
          this.loading = false
          this.numberOfCycles = 0
          this.versionOfCopilot = ''
          this.notes = ''
          this.selectedView = 1
        })
        .catch(err => {
          this.errorMessage = err.response.data
        })
    },
    deleteServiceVerify (serviceId) {
      // console.log(serviceId)
      this.dialogDelete = true
      this.serviceId = serviceId
    },
    deleteService () {
      this.loading = true
      this.$store.dispatch('deleteService', this.serviceId)
        .then(resp => {
          const index = this.machineServiceRecords.findIndex(item => item === this.serviceId)
          this.machineServiceRecords.splice(index, 1)
          this.dialogDelete = false
          this.loading = false
        })
        .catch(err => {
          this.errorMessage = err.response.data
        })
    },
    deleteCycleVerify (cycleId) {
      // console.log(cycleId)
      this.dialogDeleteCycle = true
      this.cycleId = cycleId
    },
    deleteCycle () {
      this.loading = true
      this.$store.dispatch('deleteCycle', this.cycleId)
        .then(resp => {
          const index = this.machineCycleRecords.findIndex(item => item === this.cycleId)
          this.machineCycleRecords.splice(index, 1)
          this.$emit('updateAll')
          this.dialogDeleteCycle = false
          this.loading = false
        })
        .catch(err => {
          this.errorMessage = err.response.data
        })
    },
    editService (service) {
      this.serviceEditView = service
      this.selectedView = 5

      // this.loading = true

      // this.selectedView = 2
      // this.userId = item.id
      // this.companyStreet = item.userAddress.street
      // this.companyCity = item.userAddress.city
      // this.companyPostCode = item.userAddress.postCode
      // this.companyCountry = item.userAddress.country
      // this.companyLogoLink = item.companyLogo
      // this.companyName = item.company
      // this.companyIC = item.ic
      // this.companyDIC = item.dic
      // this.customerName = item.userName
      // this.files = item.files
      // this.customerEmail = item.userEmail
      // this.customerPhoneNumber = item.userPhoneNumber
      // this.companyComment = item.notes
      // this.loading = false
    },
    setCycleRecord () {
      const cycleRecord = {
        cycleEntry: this.cycleEntry,
        enteredBy: this.$store.state.userName,
        machineId: this.machineDialogData.id
      }
      // console.log(cycleRecord)

      this.machineCycles = parseInt(this.cycleEntry)

      this.$store.dispatch('setCycleRecords', cycleRecord)
        .then(resp => {
          this.getCycleRecords()

          this.cycleEntry = 0
          this.selectedView = 1
        })
        .catch(err => {
          this.errorMessage = err.response.data
        })
    },
    mapFileExtension (file) {
      const splitted = file.split('.')
      const extension = splitted.pop().toLowerCase()
      // console.log(extension)
      if (extension === 'pdf') {
        return { icon: 'mdi-file-pdf-box', color: 'red' }
      } else if (extension === 'doc' || extension === 'docx' || extension === 'txt') {
        return { icon: 'mdi-file-word-box', color: 'blue' }
      } else if (extension === 'xls' || extension === 'xlsx' || extension === 'csv') {
        return { icon: 'mdi-file-excel-box', color: 'green' }
      } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif') {
        return { icon: 'mdi-file-image', color: 'purple' }
      } else {
        return { icon: 'mdi-file', color: 'gray' }
      }
    },
    mapFileName (file) {
      const string = file.substring(19)
      const length = 22
      return string.length > length
        ? string.substring(0, length) + '...'
        : string
    },
    openLink (link) {
      window.open(`https://systechgroup-service.warberryapps.com/files/${link}`, '_blank')
    },
    deleteFile (file) {
      const index = this.files.findIndex(item => item === file)
      if (index !== -1) {
        this.unsavedItems = true
        this.files.splice(index, 1)
      }
      // this.$store.dispatch('removeFile', file) // TO-DO
    }
  }
}

</script>

<style>
.active-button {
  background: #2d4059;
  color: white;
}
.checkbox-service {
  display: flex;
}
.position-absolute {
  position: absolute;
  z-index: 10;
top: 32%;
left:32%;

}
.file-upload {
  background: rgba(211, 211, 211, 0.178);
  min-height: 180px;
display:flex;
align-items: center;
justify-content: center;
  border-radius: 5px;
}
.file-upload-inner{
text-align: center;
}
@media only screen and (max-width: 599px) {
  .checkbox-service {
    display: block;
  }
  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
  .hide-on-mobile {
    display: none;
  }
}
</style>
